/**
 * Created by Giannis Kallergis on 2018-12-31
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

const Wrapper = styled.div`
  grid-column: -1 / 1;
  line-height: 2.5rem;
  ${props => props.withMarginTop && "margin-top: 3rem;"}
`;

Wrapper.propTypes = {
  withMarginTop: PropTypes.bool.isRequired,
};

const Title = styled.span`
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.grey.light};
`;

const TagsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: rgba(150, 150, 150, 0.1);
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 0.3rem;
`;

export default class TagsContainer extends React.PureComponent {
  static propTypes = {
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        totalCount: PropTypes.number,
      })
    ).isRequired,
    showTitle: PropTypes.bool.isRequired,
    showArticlesCount: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    tags: [],
    showTitle: true,
    showArticlesCount: false,
  };

  /** Renderers */

  _renderTagLink(tag) {
    const { name, totalCount = 0 } = tag;
    return (
      <Link key={name} to={`/tags/${kebabCase(name)}`}>
        <Tag>
          {name} {this.props.showArticlesCount && `(${totalCount || 0})`}
        </Tag>
      </Link>
    );
  }

  render() {
    if (!this.props.tags.length) {
      return null;
    }
    return (
      <Wrapper withMarginTop={!!this.props.showTitle}>
        {this.props.showTitle && <Title>Tags:</Title>}
        <TagsContent>{this.props.tags.map(tag => this._renderTagLink(tag))}</TagsContent>
      </Wrapper>
    );
  }
}
